// 获取列表
const getListURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/listSubjectsGrantedTenant`
// 导出列表
const exportListURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/exportTenantSubjects`

// 获取项目可授权应用列表 multi-select
const getApplicationListURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/listSubjectsGrantableCommunity`
// 获取项目已授权应用列表
const getGrantApplicationListURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/listSubjectsGrantedSimpleCommunity`
// 授权项目应用
const grantApplicationURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/grantSubjectsToCommunity`
// 批量授权项目应用
const bacthGrantApplicationURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/batchGrantSubjectsToCommunities`

// 应用列表 select2
const getApplicationURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/tenantSubjectSelectTo`
// 项目列表 multi-select
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList`

// 新增
const createURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/subjectCommonAdd`
// 编辑
const editURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/subjectCommonUpdate`
// 编辑 - 租户授权应用
const editGrantedURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/updateTenantSubjectGranted`
// 查询详情
const queryURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/subjectCommonView`
// 查询详情 - 租户授权应用
const queryGrantedURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/getTenantSubjectGranted`
// 选择对象
const selectObjecURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/selectObjectSelectTo`
// 查询标签
const queryLabelListURL = `${API_CONFIG.shopBaseURL}label/getLabelList`
// 获取二级分类
const querySecondCategoryURL = `${API_CONFIG.shopBaseURL}subjectCommonShu/getSubjectSubTypeBySubCode`
// 获取标签配置信息
const queryLabelConfigURL = `${API_CONFIG.shopBaseURL}subjectCommonShu/getSubjectLabelConfig`
// 获取服务类别id
const queryServiceIDURL = `${API_CONFIG.shopBaseURL}labelCategory/getLabelCategoryServerId`

// 获取项目列表
const queryCommunityListURL = `${API_CONFIG.butlerBaseURL}communityInfo/getAdminCommunityList`

export {
  getListURL,
  exportListURL,
  getApplicationListURL,
  getGrantApplicationListURL,
  grantApplicationURL,
  getApplicationURL,
  getCommunityListURL,
  createURL,
  editURL,
  editGrantedURL,
  queryURL,
  queryGrantedURL,
  selectObjecURL,
  queryLabelListURL,
  querySecondCategoryURL,
  queryLabelConfigURL,
  queryServiceIDURL,
  bacthGrantApplicationURL,
  queryCommunityListURL
}
