<template>
  <div class="PauseTimeTenantForm-wrapper">
    <form-panel
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
    >
      <col2-detail>
        <col2-block>
          <el-form-item v-if="isPlatform" label="所属公司" prop="regionId" :rules="[{ required: true, message: '请选择所属公司', trigger: 'change' }]">
            <v-select2 v-model="form.regionId" placeholder="输入租户名称" v-bind="regionParams" :width="width" @onChange="regionChange" />
          </el-form-item>
          <el-form-item label="选择应用" prop="subId" :rules="[{ required: true, message: '请选择应用', trigger: 'change' }]">
            <v-select2 v-model="form.subId" placeholder="输入应用名称" v-bind="subParams" :subjoin="subjoin"  :width="width" />
          </el-form-item>
          <el-form-item label="暂停操作" :rules="[{ required: true, message: '请选择暂停操作', trigger: 'change' }]" prop="pauseType">
            <v-select v-model="form.pauseType" :options="pauseTypeOps" :width="width" />
          </el-form-item>
          <el-form-item v-if="timeVisible" label="暂停时间" prop="time" :rules="timeRules">
            <v-datepicker :startTime.sync="form.time.pauseFrom" :endTime.sync="form.time.pauseTo" type="rangedate"></v-datepicker>
          </el-form-item>
          <el-form-item label="操作范围" :rules="[{ required: true, message: '请选择操作范围', trigger: 'change' }]">
            <checkbox-plus type="radio" :options="pauseRangeOps" :value.sync="form.pauseRange" />
          </el-form-item>
          <el-form-item v-if="communityVisible" label="选择项目" prop="communityIds" :rules="[{ type: 'array', required: true, message: '请选择项目', trigger: 'change' }]">
            <chosenListPanel :list.sync="form.communityIds" @select="selectCommunity" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
    <multi-select
      title="项目列表"
      :isShow.sync="communityShow"
      :searchUrl="getCommunityListURL"
      :headers="communityTableHeader"
      :searchParams="communitySearchParams"
      :extraParams="communityExtraParams"
      :responseParams="communityResponseParams"
      :responseKey="responseKey"
      :backFill.sync="form.communityIds"
      :handleData="handleData">
      <template #searchSlot>
        <v-input label="项目名称" v-model="communitySearchParams.communityName"></v-input>
      </template>
    </multi-select>
  </div>
</template>

<script>
import { Col2Detail, Col2Block, CheckboxPlus, MultiSelect, ChosenListPanel } from 'components/bussiness'
import { regionParams } from 'common/select2Params'
import { getCommunityListURL, getApplicationURL } from './api'
import { pauseTimeURL } from '../api'
import { setPauseTypeOps, communityStatusMap } from '../map'

export default {
  name: 'PauseTimeTenantForm',
  components: {
    Col2Detail,
    Col2Block,
    CheckboxPlus,
    MultiSelect,
    ChosenListPanel
  },
  data () {
    return {
      width: 182,
      pauseTypeOps: setPauseTypeOps(),
      regionParams,
      subParams: {
        searchUrl: getApplicationURL,
        request: {
          text: 'subName',
          value: 'id'
        }
      },
      pauseRangeOps: [{
        label: '全部项目',
        value: '1'
      }, {
        label: '选择项目',
        value: '2'
      }],
      communityShow: false,
      getCommunityListURL,
      communityTableHeader: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'communityStatusText',
          label: '项目状态'
        },
        {
          prop: 'communityStage',
          label: '项目阶段'
        },
        {
          prop: 'countryName',
          label: '所在区'
        },
        {
          prop: 'cityName',
          label: '所在市'
        },
        {
          prop: 'provinceName',
          label: '所在省'
        },
        {
          prop: 'regionName',
          label: '分子公司'
        }
      ],
      communitySearchParams: {
        communityName: ''
      },
      communityResponseParams: {
        id: 'id',
        name: 'communityName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      timeRules: [
        {
          validator: (rule, value, callback) => {
            if (value) {
              let { pauseFrom, pauseTo } = value
              if (!pauseFrom) {
                callback(new Error(`请选择开始时间`))
              } else if (!pauseTo) {
                callback(new Error(`请选择结束时间`))
              } else {
                callback()
              }
            } else {
              callback()
            }
          }
        },
        {
          required: true
        }
      ],
      form: {
        id: undefined,
        regionId: undefined,
        subId: undefined,
        pauseType: undefined,
        time: {
          pauseFrom: '',
          pauseTo: ''
        },
        pauseRange: '1',
        communityIds: []
      },
      submitConfig: {
        submitUrl: pauseTimeURL,
        submitMethod: 'PUT'
      }
    }
  },
  created () {
    this.$setBreadcrumb('设置应用暂停时间')
    this.form.pauseType = this.pauseTypeOps[0].value
  },
  computed: {
    timeVisible () {
      return this.form.pauseType === 1
    },
    isPlatform () {
      let userType = this.$store.state.userInfo.userType
      return ['106', '100'].includes(userType)
    },
    communityVisible () {
      return this.form.pauseRange === '2'
    },
    subjoin () {
      return {
        regionId: this.form.regionId
      }
    },
    communityExtraParams () {
      return {
        regionId: this.form.regionId
      }
    }
  },
  methods: {
    selectCommunity () {
      this.communityShow = true
    },
    handleData (data) {
      data.communityStatusText = communityStatusMap[data.communityStatus]
    },
    submitBefore (data) {
      if (this.timeVisible) {
        data['pauseFrom'] = data['time'].pauseFrom
        data['pauseTo'] = data['time'].pauseTo
      }
      delete data['time']
      if (this.communityVisible) {
        let communityIds = []
        data['communityIds'].forEach(item => {
          communityIds.push(item.id)
        })
        data['communityIds'] = communityIds
      } else {
        delete data['communityIds']
      }
      return true
    },
    regionChange (region) {
      this.form.communityIds = []
    }
  }
}
</script>
